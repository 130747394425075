<template>
  <div class="login">
    <section class="section is-main-section columns is-centered is-desktop">
      <div class="card column is-4">

        <div class="card-content has-text-centered">
            <p class="title">
            Register SEMESTA
            </p>
            <hr>
            <b-message
              title="Info"
              type="is-info"
              aria-close-label="Close message" v-if="isRegistered">

              <p>Registrasi Berhasil Silahkan Cek Email untuk informasi Login</p>
              <br>
              <router-link to="/login">
                <b-button type="is-primary" style="margin-right: 16px">Login</b-button>
              </router-link>

            </b-message>

            <form @submit.prevent="submit" v-else>

                <b-field label="Nama" horizontal>
                    <b-input v-model="form.name" required></b-input>
                </b-field>
                <b-field label="NIM" horizontal>
                    <b-input type="number" min="0" v-model="form.noid" required></b-input>
                </b-field>
                <b-field label="Email" horizontal>
                    <b-input type="email" v-model="form.email" required></b-input>
                </b-field>
                <b-field label="No. Hp" horizontal>
                    <b-input v-model="form.phone" required></b-input>
                </b-field>
                <b-field label="Jenjang" horizontal>
                  <v-select v-model="form.jenjang_id" label="name" :options="jenjang" :reduce="x => x.id"></v-select>
                </b-field>
                <b-field label="Konsentrasi" horizontal>
                  <v-select v-model="form.kbk_id" label="name" :options="kbk" :reduce="x => x.id"></v-select>
                </b-field>
                <b-field label="Kelas" horizontal>
                  <v-select v-model="form.kelas_id" label="name" :options="kelas" :reduce="x => x.id"></v-select>
                </b-field>
                <div>
                    <div class="has-text-right">
                        <b-button
                            type="is-primary"
                            :loading="isLoading"
                            native-type="submit">Submit
                        </b-button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Home',
  components: {
    vSelect
  },
  data () {
    return {
      isLoading: false,
      isRegistered: false,
      form: {
        email: null,
        password: null
      },
      kbk: [],
      jenjang: [],
      kelas: []
    }
  },
  computed: {
  },
  mounted () {
    axios
      .get('/public-kbk')
      .then((r) => {
        this.kbk = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    axios
      .get('/public-jenjang')
      .then((r) => {
        this.jenjang = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    axios
      .get('/public-kelas')
      .then((r) => {
        this.kelas = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.isRegistered = true
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      this.isLoading = true

      axios
        .post('/register', this.form)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>

<style scoped>

@media only screen and (min-width: 1024px) {
  .login {
    margin-left: -14rem;
  }
}

</style>
